import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

import FHA from "../images/Calculators/FHA Loan Calculator.svg";
import homeAffordability from "../images/Calculators/Home Affordability Calculator.svg";
import mortgagePayment from "../images/Calculators/Mortgage Payment Calculator.svg";
import VA from "../images/Calculators/Va Loan Calculator.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Calculators | Home Loans | Accel Mortgage"
        description="Use our free mortgage calculators to help you align your budget with your home buying goals. Then contact us when you're ready to get started."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-32 pt-16 md:mb-40 md:pt-24">
        <div className="container">
          <header className="mx-auto mb-16 max-w-[640px] text-center md:mb-20">
            <h1>Mortgage Calculators</h1>
            <p>
              Enter a few key figures in our handy mortgage calculators to get
              an estimated monthly mortgage payment.
            </p>
          </header>

          <div className="grid gap-4 text-center md:grid-cols-2 lg:grid-cols-4">
            <Link
              to="/mortgage-calculator/"
              className="w-full items-center rounded-4xl bg-primary-100 px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={mortgagePayment}
                  alt="Mortgage Payment Calculator"
                  width={56}
                  className="mx-auto mb-4"
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-4">
                  Mortgage Payment Calculator
                </h2>
                <p className="mb-0">
                  See how much your mortgage payment would be for a typical home
                  loan.
                </p>
              </div>
            </Link>

            <Link
              to="/va-loan-calculator/"
              className="w-full items-center rounded-4xl bg-primary-100 px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={VA}
                  alt="VA Loan Calculator"
                  width={56}
                  className="mx-auto mb-4"
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-4">VA Loan Calculator</h2>
                <p className="mb-0">
                  Calculate your monthly payment with $0 down and a VA funding
                  fee.
                </p>
              </div>
            </Link>

            <Link
              to="/fha-loan-calculator/"
              className="w-full items-center rounded-4xl bg-primary-100 px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={FHA}
                  alt="FHA Loan Calculator"
                  width={56}
                  className="mx-auto mb-4"
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-4">FHA Loan Calculator</h2>
                <p className="mb-0">
                  Get an estimated monthly mortgage payment amount for an FHA
                  loan.
                </p>
              </div>
            </Link>

            <Link
              to="/home-affordability-calculator/"
              className="w-full items-center rounded-4xl bg-primary-100 px-6 py-8 font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={homeAffordability}
                  alt="Home Affordability Calculator"
                  width={56}
                  className="mx-auto mb-4"
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-4">
                  Home Affordability Calculator
                </h2>
                <p className="mb-0">
                  Find out how much home you can afford to buy.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
